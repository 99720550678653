import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import '../styles/globals.css';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => typeof page;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);
  return getLayout(
    <Component key={`${router.locale}-${router.asPath}`} {...pageProps} />
  );
}

export default appWithTranslation(App);
